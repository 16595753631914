import request from '@/utils/request'

// 查询文章管理列表
export function listArticle(query) {
  return request({
    url: '/work/article/list/public',
    method: 'get',
    params: query
  })
}

// 查询文章管理详细
export function getArticle(data) {
  return request({
    url: '/work/article/getDetail/public',
    method: 'post',
    data: data
  })
}

// 新增文章管理
export function addArticle(data) {
  return request({
    url: '/work/article',
    method: 'post',
    data: data
  })
}

// 修改文章管理
export function updateArticle(data) {
  return request({
    url: '/work/article/update',
    method: 'post',
    data: data
  })
}

// 删除文章管理
export function delArticle(id) {
  return request({
    url: '/work/article/remove/' + id,
    method: 'get'
  })
}

// 导出文章管理
export function exportArticle(query) {
  return request({
    url: '/work/article/export',
    method: 'get',
    params: query
  })
}

// 获取公众号数据
export function grabDataByGzh(query) {
  return request({
    url: '/work/article/grabDataByGzh/public',
    method: 'get',
    params: query,
    timeout: 100 * 1000,
  })
}

// 获取公众号数据
export function grabVideo(query) {
  return request({
    url: '/work/article/grabVideo/public',
    method: 'get',
    params: query,
    timeout: 100 * 1000,
  })
}

// 批量获取公众号数据
export function grabDataByGzhs(data) {
  return request({
    url: '/work/article/grabDataByGzhs/public',
    method: 'post',
    data: data,
    timeout: 100 * 1000,
  })
}

// 文章阅读清零
export function addZeroNum(data) {
  return request({
    url: '/work/article/addZeroNum',
    method: 'post',
    data: data
  })
}
